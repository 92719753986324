<!-- 导航和布局 -->
<template>
  <div class="index d-flex flex-column">
    <div class="header d-flex">
      <div class="nav ">
        <span class="activate ">需求地图</span>
        <span>导航第二</span>
        <span>导航第三</span>
        <span>导航第四</span>
      </div>
      <p class="title flex-fill">黔南州数智康养城市</p>
      <div class="operation">
        <span>
          贵州省 黔南州
          <el-select class="myselect" @change="changeArea"  v-model="activateArea" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <!-- <img src="@/assets/images/drop-down.png" alt=""> -->
        </span>
        <span>
          设置
          <img src="@/assets/images/drop-down.png" alt="">
        </span>

      </div>
    </div>
    <div class="allcontent">
      <router-view />
    </div>
  </div>
</template>

<script>

export default {
  name: '',
  components: {},
  data() {
    return {
      activateArea:'资阳市',
      
    }
  },
  watch: {},
  computed: {
    options(){
      return  this.$store.state.options
    }
  },
  mounted() {

  },
  //是当 keepalive 包含的组件再次渲染的时候触发
  activated() {},
  methods: {
    changeArea(){
      this.$store.state.activateArea = this.activateArea
    },
  },
}
</script>
<style scoped lang="scss" >
.index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #002e40;
  background-image: url(~@/assets/images/bg.jpeg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .myselect{
    width: 10.2rem;
    ::v-deep .el-input__inner{
      background-color: transparent;
      color: #fff;
      border:none;
      font-size: 1.8rem;
      padding-left:0;
    }
  }

  .header {
    height: 8rem;
    background-image: url(~@/assets/images/head@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;

    width: 100%;
    position: relative;
    .nav,
    .operation {
      width: 28%;
      font-size: 1.8rem;
    }
    .nav {
      padding: 3rem 0 1rem 0;
      margin-left: 3.4rem;
      display: flex;

      justify-content: space-around;
      span {
        cursor: pointer;
        display: inline-block;
        padding: 0.8rem 1.6rem 0.4rem 1.6rem;
        position: relative;
        &.activate {
          color: #30ffea;
          background: linear-gradient(
            0deg,
            #07d5c059 0%,
            rgba(7, 213, 192, 0) 100%
          );
          &::after {
            position: absolute;
            content: ' ';
            left: 25%;
            bottom: 0;
            height: 0.3rem;
            width: 50%;
            background-color: #07d5c0;
          }
        }
      }
    }
    .operation {
      margin-right: 3.4rem;
      text-align: right;
      padding-top: 3.2rem;
      span {
        cursor: pointer;
        margin-right: 1rem;
      }
    }
    .title {
      margin-top: 0.8rem;
      letter-spacing: 0.1em;
      // width: 100%;
      font-size: 3.6rem;
      font-weight: 600;
      text-align: center;
      font-style: italic;
    }
  }
  .allcontent {
    flex: 1;
  }
}
</style>